/**
|-------------------------------------------------------------|
| App - our main presenter file                               |
|-------------------------------------------------------------|
| Here is all the presenter code that is not in the main      |
| library concatenated by grunt                               |
|-------------------------------------------------------------|
 */
import "vite/modulepreload-polyfill";

/* Polyfills and helpers, mostly for ie11 */
const isIE11 = !!window.msCrypto;
import "es6-promise-polyfill";

// Enhancements for <details>
// https://github.com/zachleat/details-utils
import "@zachleat/details-utils";

// polyfill for <dialog>
// https://github.com/GoogleChrome/dialog-polyfill
import dialogPolyfill from "dialog-polyfill";

/* CORE VUE IMPORT */

/* ALL OUR IMPORTANT EXTRA STUFF */
import { createApp, defineAsyncComponent } from "vue";
import { store } from "./store";

/* SET GLOBAL VARIABLES HERE */
const debug = process.env.NODE_ENV !== "production";

window.onerror = function (message, source, lineno, colno, error) {
	console.log("ERROR: " + message + source + lineno + error);
};

/* THE FUN STARTS HERE */
function init() {
	/* ...configure VUE! */
	const app = createApp({
		components: {
			headScript: defineAsyncComponent(() =>
				import("./components/head-script.vue")
			),
			vCustomCss: defineAsyncComponent(() =>
				import("./components/custom-css.vue")
			),
			vSelect: defineAsyncComponent(() => import("./components/select.vue")),
			vTabNav: defineAsyncComponent(() => import("./components/tab-nav.vue")),
			vTab: defineAsyncComponent(() => import("./components/tab.vue")),
			modalDialog: defineAsyncComponent(() => import("./components/modal-dialog.vue")),
			vModal: defineAsyncComponent(() => import("./components/modal.vue")),
			vTooltip: defineAsyncComponent(() => import("./components/tooltip.vue")),
			vNotification: defineAsyncComponent(() =>
				import("./components/notification.vue")
			),
			localNotification: defineAsyncComponent(() =>
				import("./components/local-notification.vue")
			),
			vCalendar: defineAsyncComponent(() =>
				import("./components/calendar.vue")
			),

			vPaginator: defineAsyncComponent(() =>
				import("./components/paginator.vue")
			),
			vVideo: defineAsyncComponent(() => import("./components/video.vue")),
			vSidebarGrid: defineAsyncComponent(() =>
				import("./components/sidebar-grid.vue")
			),
			vTextarea: defineAsyncComponent(() =>
				import("./components/textarea.vue")
			),

			vGalleryButton: defineAsyncComponent(() =>
				import("./components/gallery-button.vue")
			),
			vGalleryCarousel: defineAsyncComponent(() =>
				import("./components/gallery-carousel.vue")
			),
			vGalleryThumbs: defineAsyncComponent(() =>
				import("./components/gallery-thumbs.vue")
			),
			siteSearch: defineAsyncComponent(() =>
				import("./components/site-search.vue")
			),
			whatsOnFilters: defineAsyncComponent(() =>
				import("./components/whats-on-filters.vue")
			),
			eventSearch: defineAsyncComponent(() =>
				import("./components/event-search.vue")
			),
			exhibitionSearch: defineAsyncComponent(() =>
				import("./components/exhibition-search.vue")
			),
			articleSearch: defineAsyncComponent(() =>
				import("./components/article-search.vue")
			),
			feedRow: defineAsyncComponent(() =>
				import("./components/feed-row.vue")
			),
			vEmbed: defineAsyncComponent(() => import("./components/embed.vue")),
			eventManager: defineAsyncComponent(() =>
				import("./components/event-manager.vue")
			),
			paramLink: defineAsyncComponent(() =>
				import("./components/param-link.vue")
			),
			menuItem: defineAsyncComponent(() =>
				import("./components/menu-item.vue")
			),
			accordionTabs: defineAsyncComponent(() =>
				import("./components/accordion-tabs.vue")
			),

			axiellIndex: defineAsyncComponent(() =>
				import("./components/axiell-index.vue")
			),

			axiellEntry: defineAsyncComponent(() =>
			import("./components/axiell-entry.vue")
			),

			axiellHeader: defineAsyncComponent(() =>
			import("./components/axiell-header.vue")
			),
		},
		data() {
			return {
				jotformVisible: false,
				shouldStick: true,
			};
		},
		delimiters: ["<%", "%>"],
		methods: {
			showModal: function () {
				this.$refs.modal.openModal();
			},
			openModal: function (e) {
				this.$store.dispatch("openModal");
			},
			stickyItemChanged: function (event, currentItem, lastActiveItem) {
				if (typeof currentItem != "undefined") {
					console.log(currentItem.getAttribute("data-tab-name"), currentItem);
					document.getElementById("page-menu-title").innerHTML =
						currentItem.dataset.tabName;
				}
			},
			goToUrl: function (url, e) {
				if (e.target.localName !== "a") {
					window.location.href = url;
				}
			},
			toggleClass: function (e) {
				e.preventDefault();

				var $this = e.currentTarget;
				var $target = document.getElementById($this.dataset.toggleClassTarget);

				$this.classList.toggle("is-active");

				if ($target) {
					$target.classList.toggle("is-active");
				}
			},
			removeClass: function (e) {
				var $this = e.currentTarget;
				var $target = document.getElementById($this.dataset.toggleClassTarget);

				if ($target) {
					$target.classList.remove("is-active");
				}
			},
			selectSection: function (e) {
				let $this = e.currentTarget;
				let $parent = document.getElementById($this.dataset.selectParent);
				let $child = document.getElementById($this.dataset.selectChild);
				let $collect = document.querySelectorAll("[data-select-collect]");
				console.log($collect.length);

				$collect.forEach((item) => {
					item.classList.remove("is-active");
				});
				$parent.classList.toggle("is-active");
				$child.classList.toggle("is-active");
			},
			visibilityChanged: function (isVisible, entry) {
				console.log(isVisible);
				if (isVisible == true) {
					entry.target.classList.add("is-visible");
					console.log(entry);
				}
			},
			viewportSticky: function (e) {
				// conditional sticky
				if (window.innerWidth >= 960 && e.dataset.h - only - desktop) {
					vm.shouldStick = false;
				} else if (window.innerWidth >= 960 && e.dataset.h - except - desktop) {
					vm.shouldStick = false;
				}
			},
			smoothScroll: function (e) {
				e.preventDefault();
				let $this = e.currentTarget;
				let $target = document.getElementById($this.dataset.smoothScrollTarget);

				if ($target) {
					$target.scrollIntoView({
						behavior: "smooth",
						block: "start",
						inline: "nearest",
					});
				}
			},
		},
		mounted: function () {
			// clean up any stray menu locks
			document.body.style = "";
			if (document.readyState == "complete") {
				document.body.classList.add("is-loaded");
			}

			// CHECK TO SEE IF FLEX GAP IS SUPPORTED
			// create flex container with row-gap set
			var flex = document.createElement("div");
			flex.style.display = "flex";
			flex.style.flexDirection = "column";
			flex.style.rowGap = "1px";

			// create two elements inside it
			flex.appendChild(document.createElement("div"));
			flex.appendChild(document.createElement("div"));

			// append to the DOM (needed to obtain scrollHeight)
			document.getElementById("app").appendChild(flex);
			var isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
			flex.parentNode.removeChild(flex);

			if (!isSupported) {
				document.body.classList.add("h-flex-fix");
			}

			// START LOADING STATE
			//https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState

			if (!isIE11) {
				// console.log('run state change');
				document.onreadystatechange = function () {
					switch (document.readyState) {
						case "loading":
							// we are loading
							document.body.classList.add("is-loading");
							window.performance.mark("mark_loading");
							break;
						case "interactive":
							// The document has finished loading. We can now access the DOM elements.
							// But sub-resources such as images, stylesheets and frames are still loading.
							document.body.classList.remove("is-loading");
							document.body.classList.add("is-interactive");
							window.performance.mark("mark_interactive");
							break;
						// TODO all but 3PI
						case "complete":
							// The page is fully loaded.
							document.body.classList.add("is-loaded");
							window.performance.mark("mark_fully_loaded");
							break;
					}
				};

				//Theres a potential race condtion where (document.readyState == "complete") before our js runs, and therefore will not trigger the above loading logic. This conditional should catch that.
				if (document.readyState == "complete") {
					document.body.classList.add("is-loaded");
				}
			}

			// END LOADING STATE

			// START MENU TOGGLES

			const menuDialog = document.getElementById("menu-dialog");
			const menuOpen = document.getElementById("js-menu-open");
			const menuClose = document.getElementById("js-menu-close");

			// polyfill
			dialogPolyfill.registerDialog(menuDialog);

			if (menuDialog) {
				menuOpen.addEventListener("click", () => {
					// if header is below top of the screen (e.g there's a notification)
					// send the amount to CSS to offset the menu
					// const headerPosition =
					// 	document.getElementById("site-header").getBoundingClientRect().top;
					// if(headerPosition > 0) {
					// 	menuDialog.style.setProperty("--top", headerPosition + "px");
					// } else {
					// 	menuDialog.style.setProperty("--top", 0);
					// }

					menuDialog.showModal();
					document.documentElement.classList.add("has-open-menu");
				});

				menuClose.addEventListener("click", () => {
					menuDialog.close();
					document.documentElement.classList.remove("has-open-menu");
				});
			}

			// END MENU TOGGLES


			// START RESIZE OBSERVER LOGIC

			// if ('ResizeObserver' in self) {
			//   // Create a single ResizeObserver instance to handle all
			//   // container elements.
			//   var ro = new ResizeObserver(function(entries) {
			//     // Default breakpoints
			//     var defaultBreakpoints = {phone: 600, tablet: 960};
			//
			//     entries.forEach(function(entry) {
			//       // If breakpoints are defined on the observed element,
			//       // use them. Otherwise use the defaults.
			//       var breakpoints = entry.target.dataset.breakpoints ?
			//           JSON.parse(entry.target.dataset.breakpoints) :
			//           defaultBreakpoints;
			//
			//       // Update the matching breakpoints on the observed element.
			//       Object.keys(breakpoints).forEach(function(breakpoint) {
			//         var minWidth = breakpoints[breakpoint];
			//         if (entry.contentRect.width >= minWidth) {
			//           entry.target.classList.add(breakpoint);
			//         } else {
			//           entry.target.classList.remove(breakpoint);
			//         }
			//       });
			//     });
			//   });
			//
			//   // Find all elements with the `data-observe-resizes` attribute
			//   let elements = document.querySelectorAll('[data-observe-resizes]');
			//   elements.forEach(element => {ro.observe(element)});
			// }

			// END RESIZE OBSERVER LOGIC

			// START STICKY HEIGHT OBSERVER
			const addHeightCustomProperty = new ResizeObserver((entries) => {
				for (const entry of entries) {
					document.documentElement.style.setProperty(
						`--${entry.target.id}-height`,
						`${entry.borderBoxSize[0].blockSize}px`
					);
				}
			});

			const stickyMenu = document.querySelector(".o-page-grid__menu--sticky");

			if (stickyMenu) {
				addHeightCustomProperty.observe(
					document.querySelector(".o-page-grid__menu--sticky")
				);
			}
			// END STICKY HEIGHT OBSERVER
		},
	});

	app.use(store);

	app.mount("#app");
}

init();
